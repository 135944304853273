// import logo from "./logo.svg";
import { router } from "router";
import styles from "./App.module.scss";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import Loader from "components/loader/Loader";

function App() {
  return (
    <div className={styles.app}>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

export default App;
